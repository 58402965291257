<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Appeals - Online exams</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Appeals - Online exams
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="7">
                  <v-text-field label="Enrolment key" dense outlined clearable v-model="search.exam_key"  v-on:keyup.enter="searchAppealRequestOnlineExam"></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="5" class="text-right">
                  <v-btn :loading="isLoading"
                         @click="searchAppealRequestOnlineExam"
                         class="btn btn-primary"
                         style="color: #fff;"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="!isLoading">
                <thead>
                <tr class="text-left">
                  <th class="px-3">Candidate name</th>
                  <th class="px-3">Enrolment key</th>
                  <th class="px-3">Exam name</th>
                  <th class="px-3">Exam start date/time</th>
                  <th class="px-3">Marking status</th>
                  <th class="px-3">Priority</th>
                  <th class="px-3">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="(item,index) in appeal_request_online_exams" :key="index">
                    <td class="px-3">
                      <span  class="font-weight-bolder">
                        {{item.full_name}}
                      </span>
                      <div v-if="item.is_appeal_flagged">
                        <v-chip small
                            class="ma-2"
                            color="pink"
                            label
                            text-color="white"
                        >

                          Flagged
                        </v-chip>
                      </div>
                    </td>
                    <td class="px-3">
                      <div class="mb-1">
                        {{item.exam_key}}
                      </div>
                    </td>
                    <td class="px-3">
                      <div class="mb-1">
                        {{item.product_name}}
                      </div>
                    </td>
                    <td class="px-3">
                      <a  class="text-dark-75 font-weight-medium text-hover-primary mb-1 font-size-sm">
                        {{item.start_time_format ? item.start_time_format : 'NA'}}
                      </a>
                    </td>
                    <td>
                      <div class="mb-1" v-if="item.marking_locked">
                        Marking in Progress
                      </div>
                      <div v-else>
                        Available for Marking
                      </div>
                    </td>
                    <td>
                      <div v-html="item.marking_priority"></div>
                    </td>

                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item" v-if="!item.is_appeal_flagged">
                              <a @click="openDialog(item.candidate_id)" class="navi-link">
                                <span class="navi-icon">
                                  <v-icon color="darken-2">fa-solid fa-flag</v-icon>
                                </span>
                                <span class="navi-text">Flagged Appeal Result</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if="item.is_appeal_flagged">
                              <a @click="UnflaggedAppealOnlineExam(item.candidate_id)" class="navi-link">
                                <span class="navi-icon">
                                  <v-icon color="darken-2">fa-solid fa-flag</v-icon>
                                </span>
                                <span class="navi-text">Un-flagged Appeal Result</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>

                  </tr>
                  <tr v-if="appeal_request_online_exams.length == 0">
                    <td colspan="7" class="text-center">
                      <strong>No items added</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination :disabled="isLoading"
                            v-if="appeal_request_online_exams.length > 0"
                            class="pull-right mt-7"
                            @input="getAllAppealRequestExams"
                            v-model="page"
                            :total-rows="total"
                            :per-page="perPage"
                            first-number
                            last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="flagged_dialog" persistent  fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
              scrollable>
      <v-card>
        <v-toolbar flat>
          <v-card-title>
            <span>Assign to Online Marker</span>
            <hr>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="closeDialog">
              <i class="fa fa-close"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col md="12">
                <v-textarea dense outlined label="Flagged Remarks" v-model="candidate.appeal_flagged_remarks" :error="$v.candidate.appeal_flagged_remarks.$error"></v-textarea>
                <span class="text-danger" v-if="$v.candidate.appeal_flagged_remarks.$error">This information is required</span>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="btn btn-primary" dark medium  :loading="loading"
                 @click="flaggedAppealOnlineExam">
            Assign
          </v-btn>
          <v-btn
              color="btn btn-standard"
              dark
              medium
              @click="closeDialog"
          >
            Close
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="assign_overlay">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>
<script>
import CandidateOnlineExamResultService from "@/services/candidate/online-exam-result/CandidateOnlineExamResultService";
import OnlineExamMarkerService from "@/services/online-marker/OnlineExamMarkerService";
import OnlineMarkerService from "@/services/user/online-marker/OnlineMarkerService";
import {required} from "vuelidate/lib/validators";
import CandidateService from "@/services/candidate/CandidateService";
const candidate=new CandidateService();
const onlineMarker=new OnlineMarkerService();
const onlineExamMarker=new OnlineExamMarkerService();
const onlineExamResult=new CandidateOnlineExamResultService();
export default {
  validations: {
    candidate:{
      appeal_flagged_remarks:{required},
    }
  },
  data(){
    return{
      search:{
        exam_key:'',
      },
      isLoading:false,
      appeal_request_online_exams:[],
      dialog:false,
      loading:false,
      candidate:{
        id:'',
        is_appeal_flagged:1,
        appeal_flagged_remarks:'',
      },

      total: null,
      perPage: null,
      page: null,
      flagged_dialog:false,
      online_markers:[],
      onlineMarkerSearch:'',
      result_id:'',
      assigned_to:null,
      marking_priority:'',
      priorities:[
        {text:'High',value:'high'},
        {text:'Normal',value:'normal'},
      ],
      assign_overlay:false,
      previous_marked_by:'',
    }
  },
  methods:{
    getAllAppealRequestExams(){

      this.isLoading=true;
      onlineExamResult
          .getAllAppealRequestOnlineExamResult(this.search, this.page)
          .then(response => {
            this.appeal_request_online_exams=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch((err) => {

          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    searchAppealRequestOnlineExam(){
      this.getAllAppealRequestExams();
    },
    openDialog(candidateId){
      this.flagged_dialog=true;
      this.candidate.id=candidateId;
    },

    closeDialog(){
      this.flagged_dialog=false;
      this.result_id=null;
      this.assigned_to=null;
      this.previous_marked_by=null;
      this.onlineMarkerSearch=null;
      this. candidate={
        id:'',
        is_appeal_flagged:'',
        appeal_flagged_remarks:'',
      };
      this.$v.$reset();
    },
    flaggedAppealOnlineExam(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        this.candidate.is_appeal_flagged=1;
        candidate
            .update(this.candidate.id,this.candidate)
            .then(response => {
              this.$snotify.success("Exam result marked for appeal");
              this.getAllAppealRequestExams();
              this.closeDialog();
            }).catch((err) => {

        })
            .finally(() => {

            });
      }
    },
    UnflaggedAppealOnlineExam(candidateId){
      this.$confirm({
        message: `Are you sure ? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.candidate.is_appeal_flagged=0;
            candidate
                .update(candidateId,this.candidate)
                .then(response => {
                  this.$snotify.success("Appeal Un-Flagged Successfully");
                  this.getAllAppealRequestExams();
                })
                .catch((err) => {
                })
                .finally(() => {

                });
          }
        },
      });



    },

  },
  watch:{

  },
  mounted() {
    this.getAllAppealRequestExams();
  }
}
</script>